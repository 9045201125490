.App {
  text-align: center;
}

#logo-image {
  max-height: 72px;
  max-width: 100px;
  aspect-ratio: 'auto';
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.card-grid {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.credit-card {
  display: flex;
  flex-direction: row;
}

.card-form {
  margin-left: 10px;
}

.MuiTextField-root {
  margin: 10px;
}
.double-div {
  display: flex;
  flex-direction: row;
  z-index: 5;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#gradient-background {
  width: 100%;
  /* width: 30wh;
  height: 30vh;
  color: #fff; */
  margin-top: 0;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
  z-index: 10;
}

#home-header {
  width: 100%;
  margin-top: 0;
  background: #bcbcbc;
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
  z-index: 10;
}

#app-header {
  width: 100%;
  margin-top: 0;
  background: #888;
  background-size: 400% 400%;
  z-index: 10;
  max-width: 1100px;
  margin: auto;
  border-radius: 0 0 1rem 1rem;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.home-container {
  margin: auto;
  max-width: 1000px;
}

.home-wrapper {
  /* background-image: url('https://secureservercdn.net/160.153.137.210/r79.027.myftpupload.com/wp-content/uploads/2019/11/hair-removal-milton-keynes-2-2048x1192.jpg');
  height: 100vh;
  width: 100%;
  filter: blur(4px);
  position: absolute; */
  background-color: #666;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 2rem;
}

.container-home-screen {
  background-color: #f7f7f7;
  padding: 1rem;
  max-width: 1100px;
  margin: auto;
  margin-top: 2rem;
  border-radius: 1rem;
  z-index: 5;
}

.fader {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.26;
  background-color: black;
}

.home-grid {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.home-item {
  border-radius: 10px;
  margin-bottom: 20px;
  margin-left: 15px;
  margin-right: 15px;
  z-index: 5;
}

.home-item img {
  width: 100%;
  height: 280px;
  object-fit: contain;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.home-item-footer-container {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #ccc;
  margin-top: 0;
  position: relative;
  padding-bottom: 5px;
}

/* .home-item-footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
} */

.home-item-footer-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
}
.home-item-footer-bottom-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
}
.home-item-footer-bottom-container div {
  display: flex;
  flex-direction: row;
}
.home-item-footer-bottom-container div > * + * {
  margin-left: 5px;
}

.home-item-footer-category-label {
  position: absolute;
  width: fit-content;
  height: auto;
  color: #fff;
  left: 15px;
  top: -20px;
  padding: 5px;
  font-size: 18px;
  font-weight: 800;
  border-radius: 1px;
  background-color: #42b4bd;
  overflow: hidden;
}

.home-backgroud-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 0;
  object-fit: cover;
}

.home-title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 5;
  margin-bottom: 2vw;
}

.logo-container {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  z-index: 10;
}

.home-header-container {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  z-index: 10;
}

.home-cart-drawer-icon {
  display: flex;
  margin: auto;
}

.invisible-layer {
  pointer-events: none;
  visibility: hidden;
}

.book-item-card {
  padding: 1rem;
}
